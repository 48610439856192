/* this file is transformed by vux-loader */
/* eslint-disable */
import { keepTwoDecimalFull } from "../../../assets/app.js";
export default {
  props: {
    wpInfo: {
      default: ""
    }
  },
  data() {
    return {
      datas: {},
      showWpflag: false,
      showWpflagdz: false,
      dwList: [],
      dwData: [],
      yzmcList: ["服务", "供应品"] //["一般物品","固定资产","服务类"],
    };
  },

  computed: {
    total: function () {
      let zj = keepTwoDecimalFull(this.wpInfo.sqsl * this.wpInfo.price);
      return zj;
    }
  },
  created() {
    console.log('组件内数据', this.wpInfo);
    var url = _baseUrl + `/servicegyp/hggysml/gysTable/listData.do`;
    this.$ajax.post(url).then(res => {
      console.log(res);
      this.dwData = res.data.data;
      for (var i = 0; i < this.dwData.length; i++) {
        this.dwList[i] = this.dwData[i].dwmc;
      }
    });
  },
  methods: {
    onWpflagdz(value) {
      this.wpInfo.dgdw = value;
      this.showWpflagdz = false;
    },
    onWpflag(value) {
      this.wpInfo.zt = value;
      this.showWpflag = false;
    },
    onProps() {
      this.datas = this.wpInfo;
      this.datas._state = "added";
      this.datas.total = this.total;
      this.$emit("wpListInfo", this.datas);
    }
  }
};